import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";

// Extend dayjs with the necessary plugins
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

// Helper function to format UTC date in user's local timezone and get timezone abbreviation
export const formatDateInUserTimezone = (utcDate, DATE_FORMAT = 'MM/DD/YYYY') => {
  // Get the user's timezone
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert UTC date to user's timezone and format it
  const formattedDate = dayjs.utc(utcDate).tz(userTimezone).format(`${DATE_FORMAT} hh:mm:ss A`);

  // Get timezone abbreviation
  const timezoneAbbreviation = dayjs().tz(userTimezone).format('z'); // 'z' gives the abbreviation (e.g., IST, PST)

  return {
    formattedDate,
    timezoneAbbreviation: '',
  };
};


// Function to convert entered date and time into UTC format
export const convertToUTC = (dateString, timeString, DATE_FORMAT = 'MM/DD/YYYY') => {
  // Combine date and time strings (e.g., "12-1-2024" and "01:30")
  const combinedDateTime = `${dateString} ${timeString}`;

  // Parse the combined date and time in a local format (assuming it's in the local timezone)
  const localDateTime = dayjs(combinedDateTime, `${DATE_FORMAT} hh:mm:ss A`);

  // Convert the local date and time to UTC and return it in ISO 8601 format
  return localDateTime.utc().format(); // or use `.toISOString()` for standard ISO format
};

export const convertFullDateToUTC = (dateWithTime, DATE_FORMAT = 'MM/DD/YYYY') => {
  
  const parsedDateTime = dayjs(dateWithTime, `${DATE_FORMAT} hh:mm:ss A`);
  console.log("dateWithTIme ", {dateWithTime, DATE_FORMAT, parsedDateTime})

  return convertToUTC(parsedDateTime.format(DATE_FORMAT), parsedDateTime.format('hh:mm:ss A'), DATE_FORMAT);
}
