import RoasterService from "../../../service/RoasterService";

export const SET_ABO_SEARCH_TERM = 'SET_ABO_SEARCH_TERM';
export const SET_COURSE_STATUS_DROPDOWN = 'SET_COURSE_STATUS_DROPDOWN';
export const SET_AFFILIATE_CODE = 'SET_AFFILIATE_CODE';
export const SET_ABO_LIST_LOADER = 'SET_ABO_LIST_LOADER';
export const SET_ABO_LIST = 'SET_ABO_LIST';
export const SET_SELECTED_ABO_LIST = 'SET_SELECTED_ABO_LIST';
export const SET_ABO_LIST_SORT_PROPS = 'SET_ABO_LIST_SORT_PROPS';
export const SET_SELECTED_AFF_CODE = 'SET_SELECTED_AFF_CODE';
export const SET_ABO_LIST_PAGINATION_PROPS = 'SET_ABO_LIST_PAGINATION_PROPS';
export const SET_EDIT_FORM_COURSE_START_DATE = 'SET_EDIT_FORM_COURSE_START_DATE';
export const SET_EDIT_FORM_COURSE_STATUS_DATE = 'SET_EDIT_FORM_COURSE_STATUS_DATE';
export const SET_EDIT_FORM_COURSE_PASSED_STATUS = 'SET_EDIT_FORM_COURSE_PASSED_STATUS';
export const SET_EDIT_FORM_COURSE_SCORE = 'SET_EDIT_FORM_COURSE_SCORE';

export const setEditFormCourseScore = (options) => {
  return {
    type: SET_EDIT_FORM_COURSE_SCORE,
    payload: options,
  };
};

export const setAboSearchTerm = (options) => {
  return {
    type: SET_ABO_SEARCH_TERM,
    payload: options,
  };
};

export const setCourseStatusDropdown = (options) => {
  return {
    type: SET_COURSE_STATUS_DROPDOWN,
    payload: options,
  };
};

export const setAffiliateCode = (options) => {
  return {
    type: SET_AFFILIATE_CODE,
    payload: options,
  };
};

export const setSelectedAffiliateCode = (options) => {
  return {
    type: SET_SELECTED_AFF_CODE,
    payload: options,
  };
}

export const setAboListLoader = (options) => {
  return {
    type: SET_ABO_LIST_LOADER,
    payload: options,
  };
}

export const setAboList = (options) => {
  return {
    type: SET_ABO_LIST,
    payload: options,
  };
}

export const setSelectedAboList = (options) => {
  return {
    type: SET_SELECTED_ABO_LIST,
    payload: options,
  };
}

export const setAboListSortProps = (options) => {
  return {
    type: SET_ABO_LIST_SORT_PROPS,
    payload: options,
  };
}

export const setAboListPaginationProps = (options) => {
  return {
    type: SET_ABO_LIST_PAGINATION_PROPS,
    payload: options,
  };
};

export const setEditFormStartDate = (options) => {
  return {
    type: SET_EDIT_FORM_COURSE_START_DATE,
    payload: options,
  };
};

export const setEditFormStatusDate = (options) => {
  return {
    type: SET_EDIT_FORM_COURSE_STATUS_DATE,
    payload: options,
  };
};

export const setCoursePassedDropdown = (options) => {
  return {
    type: SET_EDIT_FORM_COURSE_PASSED_STATUS,
    payload: options,
  };
};

export const fetchAboList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setAboListLoader(true));

      const response = await RoasterService.getAboList(payload);
      
      dispatch(setAboList(response?.data?.learningRecords || []));
      dispatch(setAboListPaginationProps({ exclusiveStartKey: response?.data?.learningRecords?.length > 0 ? response?.data?.lastEvaluatedKey : null }))
      dispatch(setAboListLoader(false));

    } catch (error) {
      console.log('error', error);
      dispatch(setAboList([]));
    } finally {
      dispatch(setAboListLoader(false));
    }
  };
};

export const deleteLearningRecords = ({ selectedAboList, selectedCourse, selectedAffCode }, fetchAboListPayload) => {
  return async (dispatch) => {
    try {
      dispatch(setAboListLoader(true));

      const response = await RoasterService.deleteLearningRecords({ selectedAboList, selectedCourse, selectedAffCode });

      dispatch(fetchAboList(fetchAboListPayload));
      console.log('response-->', response);

    } catch (error) {
      console.log('error', error);
    } finally {
      dispatch(setAboListLoader(false));
    }
  };
};
